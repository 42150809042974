import * as React from "react";
import { HeadFC } from "gatsby";
import Header_Layout from "../components/layouts/Header_Layout";
import "../styles/main.css";
import Contact_Panel from "../components/global/Contact_Panel";

const page_styles = {
  paddingTop: 16,
  paddingLeft: 96,
  paddingBottom: 96,
  paddingRight: 96,
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
  fontSize: 24,
};

const ContactPage = () => {
  return (
    <Header_Layout>
      <main style={page_styles}>
        <Contact_Panel request_appraisals={false} />
      </main>
    </Header_Layout>
  );
};

export default ContactPage;

export const Head: HeadFC = () => <title>Contact Us - BitLot.app</title>;
